/* src/LogoSlider.css */
.slider {
    overflow: hidden;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
}

.slide-track {
    display: flex;
    animation: scroll 30s linear infinite;
}

.slide {
    width: 250px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.slide img {
    max-width: 100%;
    max-height: 100px; /* Adjust height as needed */
    object-fit: contain;
}

@keyframes scroll {
    0% { transform: translateX(0); }
    100% { transform: translateX(calc(-250px * 10)); } /* Adjust based on the number of slides */
}
